<i18n>
{
  "en": {
		"applicationLabel": "Add new resort application",
		"openApplications": {
			"title": "Applications",
			"info": "If you'd like to cancel a pending item, open it from the list below and click the \"Cancel application\" button."
		},
		"confirmedApplications": {
			"title": "Confirmed applications",
			"noItemsText": "No confirmed applications to show."
		},
		"canceledApplications": {
			"title": "Canceled applications",
			"noItemsText": "No canceled applications to show."
		},
		"dialogTitlePending": "Application",
		"dialogTitle": "Application",
		"cancelApplication": "Cancel application",
		"submitApplication": "Submit new application",
		"canceled": "canceled",
		"labels": {
			"resort": "Confirmed resort",
			"selectedResorts": "Selected resorts",
			"preferredResort": "Preferred resort",
			"applicationPeriod": "Application period",
			"startDate": "Start date",
			"endDate": "End date",
			"preferredStartDate": "Preferred start date",
			"alternativeStartDate": "Alternative start date",
			"otherPossibleDates": "Other possible dates",
			"firstTimeVisitor": "Are you a first time visitor?",
			"takingPet": "Taking a pet with me"
		},
		"confirm": {
			"title": "Confirm cancellation",
			"text": "Are you sure you want to cancel your application for application period {period}?"
		}
	},
  "fi": {
		"applicationLabel": "Tee uusi lomapaikkahakemus",
		"openApplications": {
			"title": "Hakemukset",
			"info": "Jos haluat perua hakemuksen, valitse se alla näkyvästä listasta ja klikkaa \"Peruuta hakemus\" -painiketta."
		},
		"confirmedApplications": {
			"title": "Vahvistetut varaukset",
			"noItemsText": "Vahvistettuja varauksia ei löytynyt"
		},
		"canceledApplications": {
			"title": "Peruutetut varaukset",
			"noItemsText": "Peruutettuja varauksia ei löytynyt"
		},
		"dialogTitlePending": "Hakemus",
		"dialogTitle": "Varaus",
		"cancelApplication": "Peruuta hakemus",
		"submitApplication": "Lähetä uusi hakemus",
		"canceled": "peruutettu",
		"labels": {
			"resort": "Vahvistettu lomakohde",
			"selectedResorts": "Haetut lomakohteet",
			"preferredResort": "Ensisijainen lomakohde",
			"applicationPeriod": "Lomapaikan hakuaika",
			"startDate": "Alkaa",
			"endDate": "Päättyy",
			"preferredStartDate": "Ensisijainen aloitusaika",
			"alternativeStartDate": "Vaihtoehtoinen aloitusaika",
			"otherPossibleDates": "Muut ajankohtatoiveet",
			"firstTimeVisitor": "Oletko ensikertalainen?",
			"takingPet": "Lemmikkieläin mukana",
			"petResortAllowed": "Voin majoittua lemmikkieläinhuoneistoon"
		},
		"confirm": {
			"title": "Vahvista peruutus",
			"text": "Haluatko varmasti peruuttaa hakemuksesi hakuajalle {period}?"
		}
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="success === true"
			class="container--narrow"
		>
			<v-alert
				type="success"
				class="multi-line ma-0"
			>
				<div v-html="data.application_success" />
			</v-alert>
		</v-container>

		<!-- Open applications -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>{{ $i18n.t('openApplications.title') }}</v-card-title>
				<v-divider />
				<v-card-text>
					<template v-if="openItems.length">
						<v-alert type="info">
							{{ $i18n.t('openApplications.info') }}
						</v-alert>
						<ResortApplicationList
							:items="openItems"
							@itemClick="openDialog"
						/>
					</template>
					<v-btn
						v-else
						block
						large
						color="primary"
						:to="({ name: 'resortsForm' })"
					>
						<v-icon left>
							mdi-send
						</v-icon>
						{{ $i18n.t('applicationLabel') }}
					</v-btn>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- Confirmed items -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>{{ $i18n.t('confirmedApplications.title') }}</v-card-title>
				<v-divider />
				<v-card-text>
					<ResortApplicationList
						:items="confirmedItems"
						:no-items-text="$i18n.t('confirmedApplications.noItemsText')"
						@itemClick="openDialog"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- Canceled items -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>{{ $i18n.t('canceledApplications.title') }}</v-card-title>
				<v-divider />
				<v-card-text>
					<ResortApplicationList
						:items="canceledItems"
						:no-items-text="$i18n.t('canceledApplications.noItemsText')"
						@itemClick="openDialog"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- application dialog -->
		<v-dialog
			v-model="dialogOpen"
			max-width="500"
			persistent
			scrollable
		>
			<v-card>
				<v-card-title>
					{{ openItem.resort ? $i18n.t('dialogTitle') : $i18n.t('dialogTitlePending') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<SimpleListItem
						:title="$i18n.t('labels.applicationPeriod')"
						:subtitle="openItem.applicationPeriod"
					/>
					<template
						v-if="openItem.resort"
					>
						<SimpleListItem
							:title="$i18n.t('labels.resort')"
							:subtitle="openItem.resort"
						/>
						<SimpleListItem
							:title="$i18n.t('labels.startDate')"
							:subtitle="openItem.startDate"
						/>
						<SimpleListItem
							:title="$i18n.t('labels.endDate')"
							:subtitle="openItem.endDate"
						/>
					</template>
					<template
						v-else
					>
						<SimpleListItem
							:title="$i18n.t('labels.selectedResorts')"
							:subtitle="openItem.selectedResorts"
						/>
						<SimpleListItem
							:title="$i18n.t('labels.preferredResort')"
							:subtitle="openItem.preferredResort"
						/>
						<SimpleListItem
							:title="$i18n.t('labels.preferredStartDate')"
							:subtitle="openItem.preferredStartDate"
						/>
						<SimpleListItem
							:title="$i18n.t('labels.alternativeStartDate')"
							:subtitle="openItem.alternativeStartDate"
						/>
						<SimpleListItem
							:title="$i18n.t('labels.otherPossibleDates')"
							:subtitle="openItem.otherPossibleDates"
						/>
					</template>
					<SimpleListItem
						:title="$i18n.t('labels.firstTimeVisitor')"
						:subtitle="openItem.firstTimeVisitor"
					/>
					<SimpleListItem
						:title="$i18n.t('labels.takingPet')"
						:subtitle="openItem.takingPet"
					/>
					<SimpleListItem
						:title="$i18n.t('labels.petResortAllowed')"
						:subtitle="openItem.petResortAllowed"
					/>
					<v-btn
						v-if="openItem.id && !openItem.resort"
						color="error"
						class="mt-6"
						@click="confirmSave"
					>
						<v-icon
							left
						>
							mdi-calendar-remove
						</v-icon>
						{{ $i18n.t('cancelApplication') }}
					</v-btn>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-spacer />
					<v-btn
						color="primary"
						text
						@click="close"
					>
						{{ $i18n.t('general.close') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- removal confirmation dialog -->
		<v-dialog
			v-model="confirmDialogOpen"
			max-width="500"
			persistent
			scrollable
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('confirm.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					{{ $i18n.t('confirm.text', { period: openItem.applicationPeriod }) }}
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						color="error"
						text
						v-if="!openItem.resort"
						:disabled="saveLoading"
						@click="save"
					>
						<v-icon
							left
						>
							mdi-calendar-remove
						</v-icon>
						{{ $i18n.t('cancelApplication') }}
					</v-btn>
					<v-spacer />
					<v-btn
						color="primary"
						text
						:disabled="saveLoading"
						:loading="saveLoading"
						@click="close"
					>
						{{ $i18n.t('general.close') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import SimpleListItem from '../components/SimpleListItem.vue'
import ResortApplicationList from '../components/ResortApplicationList.vue'

export default {
	name: 'ResortApplications',
	components: {
		SimpleListItem,
		ResortApplicationList,
	},
	props: {
		success: {
			type: [Boolean],
			required: false,
			default () {
				return false
			},
		},
	},
	data: () => ({
		searchQuery: '',
		openItem: {},
		dialogOpen: false,
		confirmDialogOpen: false,
		saveLoading: false,
	}),
	computed: {
		...mapState({
			data: state => state.resortsData,
			items: state => state.resortApplications,
		}),
		openItems () {
			return this.items.filter(item => item.resort === null && item.canceled === false)
		},
		confirmedItems () {
			return this.items.filter(item => item.resort !== null && item.canceled === false)
		},
		canceledItems () {
			return this.items.filter(item => item.resort !== null && item.canceled === true)
		},
	},
	mounted () {
		this.$api.ResortApplications.doRequest()
	},
	methods: {
		openDialog (item) {
			this.openItem = Object.assign({}, item)
			this.dialogOpen = true
		},

		close () {
			this.dialogOpen = false
			this.confirmDialogOpen = false
			this.openItem = {}
		},

		confirmSave () {
			this.dialogOpen = false
			this.confirmDialogOpen = true
		},

		save () {
			this.saveLoading = true

			this.$api.ResortApplications.doRequest({
				url: 'remove',
				method: 'POST',
				body: this.openItem,
			}).on('done', (res) => {

				// Reset dialog and open item
				this.close()

				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>

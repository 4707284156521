<i18n>
{
	"en": {
		"card": {
			"entityNumber": "Membership number",
			"editYourDetails": "Edit your details"
		},
		"news": {
			"title": "News",
			"allNewsButtonText": "Show all news",
			"noItemsText": "No news to show."
		}
	},
	"fi": {
		"card": {
			"entityNumber": "Jäsennumero",
			"editYourDetails": "Päivitä tietojasi rauasioinnissa"
		},
		"news": {
			"title": "Uutiset",
			"allNewsButtonText": "Näytä kaikki uutiset",
			"noItemsText": "Uutisia ei löytynyt"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="config._hero_image"
					class="white--text align-end"
					aspect-ratio="2"
					:src="config._hero_image.url"
					gradient="rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%"
					dark
				>
					<v-card-title class="headline">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.entity_number"
						class="white--text"
					>
						{{ $i18n.t('card.entityNumber') }}: {{ user.entity_number }}
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.membership.length"
						class="white--text"
					>
						<ul class="no-list">
							<li
								v-for="item in user.membership"
								:key="item.id"
							>
								{{ item.title }}
							</li>
						</ul>
					</v-card-subtitle>
				</template>
				<v-card-actions>
					<v-btn
						href="https://tunnistus.avoine.fi/sso-login/?service=rau-lotta&return=https%3A%2F%2Frauasiointi.raury.fi%2Fauth"
						target="_blank"
						color="secondary"
						text
					>
						<v-icon left>
							mdi-pencil
						</v-icon>
						{{ $i18n.t('card.editYourDetails') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- News for members -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('news.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="memberNews"
						subtitle-src="date"
						:enable-search="false"
						:max-items="4"
						:meta-src="null"
						redirect-url-src="link"
						:no-items-text="$i18n.t('news.noItemsText')"
						@itemClick="item => $router.push({ name: 'news', params: { pagename: item.name } })"
					>
						<template #title="slotProps">
							{{ slotProps.item.title }}

							<v-chip
								v-if="slotProps.item.badge"
								color="secondary"
								small
								class="ml-2"
							>
								{{ slotProps.item.badge }}
							</v-chip>
						</template>
					</FilterableList>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'newsContainer' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-bullhorn
						</v-icon>
						{{ $i18n.t('news.allNewsButtonText') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
			memberNews: state => state.news.member,
		}),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>

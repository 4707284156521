<template>
	<div v-if="item">
		<v-container
			v-if="item.hero_image"
			class="container--narrow"
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				aspect-ratio="1.333"
			/>
		</v-container>
		<v-container class="container--narrow pt-6">
			<v-chip
				v-if="item.badge"
				color="secondary"
				small
				class="mb-3"
			>
				{{ item.badge }}
			</v-chip>
			<h1 class="headline mb-3">
				{{ item.headline }}
			</h1>
			<p
				v-if="item.date"
				class="subtitle-1"
			>
				<span
					v-if="item.date"
					class="mr-2"
				>
					{{ item.date }}
				</span>
			</p>
			<p
				v-if="item.summary"
				class="font-weight-medium"
				v-html="item.summary"
			/>
			<ContentBlocks :items="item.content_blocks" />
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'News',
	data: () => ({
		item: null,
	}),
	computed: {
		...mapState({
			memberNews: state => state.news.member,
		}),
	},
	mounted () {
		// Find current page from the store
		this.item = this.memberNews.find(item => {
			return item.name == this.$route.params.pagename
		})

		if (!this.item) {
			this.$router.replace({ name: 'error404' })
		}

		// Set custom headline
		this.$nextTick(() => {
			this.$root.$emit('setHeadline', this.item.title)
		})
	},
}
</script>
